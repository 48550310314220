import React, { useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import projects from "../data.json";

const ProjectGrid = () => {
  const gridVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: [0.8, 0.4, 0.8, 1], transition: { duration: 0.3 } },
    exit: {
      opacity: [0.4, 0.8, 0.2, 0],
      transition: { duration: 0.2, ease: "easeOut" },
    },
  };

  return (
    <motion.div
      className="project-grid"
      variants={gridVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {projects.map((project) => (
        <Link
          to={`/project/${project.id}`}
          key={project.id}
          className="project-item"
        >
          <img
            src={project.image}
            alt={project.title}
            className="project-thumbnail"
          />
          <h3>{project.title}</h3>
        </Link>
      ))}
    </motion.div>
  );
};

const ProjectDetails = ({ id }) => {
  const navigate = useNavigate();
  const project = projects.find((proj) => proj.id === id);

  useEffect(() => {
    if (project) {
      document.title = `${project.title} - Abdullah Alzeer`;
    }
    return () => {
      document.title = "Abdullah Alzeer - Expert in XR and Game Development";
    };
  }, [project]);

  if (!project) {
    return <p>Project not found!</p>;
  }

  const detailVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: [0.8, 0.4, 0.8, 1],
      transition: { duration: 0.3, ease: "easeIn" },
    },
    exit: { opacity: [0.4, 0.8, 0.2, 0], transition: { duration: 0.2 } },
  };

  return (
    <motion.div
      className="project-detail"
      variants={detailVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="project-header">
        <button className="back-button" onClick={() => navigate(-1)}>
          <svg
            className="back-icon"
            fill="#fff"
            version="1.1"
            width="16px"
            height="16px"
            viewBox="0 0 42 42"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
          >
            <polygon
              fillRule="evenodd"
              points="27.066,1 7,21.068 26.568,40.637 31.502,35.704 16.865,21.068 32,5.933"
            />
          </svg>
          Back
        </button>
        <h2 className="project-title">{project.title}</h2>
      </div>
      <img src={project.image} alt={project.title} className="project-image" />
      <h3>Overview</h3>
      <p>{project.description}</p>
      {project.galleryImages.length > 0 && (
        <div className="gallery">
          {project.galleryImages.map((img, index) => (
            <img src={img} alt={`Gallery ${index + 1}`} key={index} />
          ))}
        </div>
      )}
      {project.youTubeVideo && (
        <div className="video">
          <iframe
            width="100%"
            height="480"
            src={project.youTubeVideo}
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </motion.div>
  );
};

const Home = () => {
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      document.title = "Abdullah Alzeer";
    }
  }, [id]);

  return (
    <>
      {/* Hero Section */}
      <section className="hero">
        <h1 className="title">Abdullah Alzeer</h1>
        <p className="subtitle">Expert in XR and Game Development</p>
        <div className="links">
          <a
            href="https://www.linkedin.com/in/abdullah-al-zeer/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          <a href="mailto:abdullahzeer27@gmail.com">Email</a>
        </div>
      </section>

      {/* Bio Section */}
      <section className="bio">
        <p>
          I create immersive XR experiences and games, blending creativity and
          technical expertise to deliver high-quality, engaging projects. Driven
          by curiosity, I constantly explore new technologies to push the
          boundaries of XR and game development.
        </p>
      </section>

      {/* Projects Section */}
      <section className="projects">
        <h2>Work</h2>
        <AnimatePresence mode="wait">
          {!id ? (
            <ProjectGrid key="grid" />
          ) : (
            <ProjectDetails key="details" id={id} />
          )}
        </AnimatePresence>
      </section>
    </>
  );
};

export default Home;
