import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles.css";
import Home from "./pages/Home";

export default function App() {
  return (
    <Router>
      <div className="App">
        {/* Dots Background */}
        <div className="dots-background"></div>

        {/* Design Guide Lines */}
        <div className="design-guides">
          <div className="line"></div>
          <div className="vertical-line"></div>
        </div>

        <Routes>
          {/* Main Route */}
          <Route path="/" element={<Home />} />
          {/* Project Details Route */}
          <Route path="project/:id" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}
